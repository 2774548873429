<template>
    <section>
        <modal ref="modalEntregaFinal" v-loading="loadingRecursos" :element-loading-text="`${mensajeCarga}`" :titulo="titulo" tamano="modal-lg" adicional="Enviar" :no-aceptar="true" :desactivar-guardar="false" @adicional="enviarEntrega">
            <ValidationObserver ref="validacion">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col">
                        <p class="f-22 text-gris mb-4 descripcion">
                            {{ descripcion }}
                        </p>
                        <div class="row mx-0 my-4">
                            <el-upload
                            ref="upload"
                            class="upload-demo"
                            action="#"
                            :auto-upload="false"
                            multiple
                            :limit="10"
                            :on-exceed="limiteExedido"
                            :on-remove="quitarArchivos"
                            :on-change="handleFileAdjuntos"
                            >
                                <el-button size="small" class=" btn btn-general">
                                    Clic para subir archivo
                                </el-button>
                                <div slot="tip" class="f-12">
                                    Tamaño máximo 30 MB por archivo
                                </div>
                            </el-upload>
                        </div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:500" name="comentario">
                            <div class="row mx-0 mb-3">
                                <p class="f-15 pl-2 mb-2">
                                    Deje un breve comentario sobre su experiencia de aprendizaje en este curso
                                </p>
                                <el-input v-model="model.comentario" type="textarea" :rows="5" class="area-radius" placeholder="Texto..." maxlength="500" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto" />
                </div>
            </ValidationObserver>
        </modal>
    </section>
</template>

<script>
import Curso from "../../../services/cursos/ver_cursos";
import Aws from "../../../services/aws";

export default {
    data(){
        return {
            id_entrega: null,
            id_eliminar: null,
            model:{
                comentario: '',
                archivos: [],
            },
            files: [],
            adjuntos: [],
            titulo: '',
            descripcion: '',
            mensajeCarga:'',
            contador:0,
            loadingRecursos:false,
        }
    },
    methods: {
        toggle(row){
            this.id_entrega = row.id
            this.titulo = row.titulo
            this.descripcion = row.descripcion
            this.limpiar()
            this.$refs.modalEntregaFinal.toggle();
        },
        handleFileAdjuntos(file, fileList){
            const isLt30M = file.size / 1024 / 1024 < 30 ;
            if (!isLt30M){
                fileList.splice(file,1)
                this.notificacion('Alerta','El archivo excede los 30MB','warning')
                return
            }
            this.files.push(file)
        },
        limiteExedido(){
            this.notificacion(`Solo se permite subir 10 archivos`,'warning');
        },
        quitarArchivos(file,fileList){
            this.files.splice(this.files.indexOf(file.raw),1)
            fileList.splice(fileList.indexOf(file.raw),1)
        },
        async enviarEntrega(){
            try {
                let url = []
                let that = this
                this.contador = 0
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.loadingRecursos = true
                if (this.files.length){
                    for (let adjunto of this.files){
                        let ruta = `descargables/${Aws.getHash()}.${adjunto.raw.name.split('.').pop()}`
                        let resp = await Aws.getInstance().upload({
                            Key: ruta,
                            ContentType: adjunto.raw.type,
                            Body: adjunto.raw
                        }).on('httpUploadProgress',uploadEvent=>{
                            let porcentaje = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                            //that.mensajeCarga = `${porcentaje}%`
                            that.mensajeCarga = `Recursos ${that.contador}/${that.files.length} => ${porcentaje}%`
                        }).promise()
                        url.push({url:resp.Key, nombre: adjunto.name})
                        this.contador++
                    }
                }
                this.model.archivos = url
                this.model.id_curso = this.$route.params.id_curso
                this.model.id_entrega = this.id_entrega

                const { data: { data, message } } = await Curso.enviarEntrega(this.model)
                this.$emit('enviar-entrega',data)
                this.notificacion('Exito!', message, 'success')
                this.$refs.modalEntregaFinal.toggle();
                
            } catch (error){
                this.error_catch(error)

            }finally{
                this.loadingRecursos = false
            }

        },
        limpiar(){
            this.model = {
                comentario: '',
                archivos: [],
            }
            this.$refs.upload.clearFiles()
            this.$refs.validacion.reset()
        }
    }
}
</script>

<style lang="scss" scoped>
.descripcion{
    height: 80px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>